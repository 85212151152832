import { AppInterest } from '@/resources/forge';

const store = {
  namespaced: true,
  state: {
    registeredApps: []
  },
  mutations: {
    setRegisteredApps(state, apps) {
      state.registeredApps = apps;
    },
    addRegisteredApp(state, app) {
      state.registeredApps.push(app);
    }
  },
  actions: {
    async registerInterest({ commit }, { appId, appName }) {
      try {
        const response = await AppInterest.register({ 
          app_interest: { 
            app_id: appId,
            app_name: appName
          }
        });
        
        if (response.body.success) {
          commit('addRegisteredApp', { id: appId, name: appName });
          return true;
        }
        return false;
      } catch (error) {
        console.error('Error registering interest:', error);
        return false;
      }
    },
    
    async loadRegisteredApps({ commit }) {
      try {
        const response = await AppInterest.get();
        commit('setRegisteredApps', response.body.registered_apps);
        return response.body.registered_apps;
      } catch (error) {
        console.error('Error loading registered apps:', error);
        return [];
      }
    }
  }
};

export default store; 