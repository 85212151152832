<template>
  <div class="auth-wrapper">
    <v-card class="auth-container modal_window mx-auto pa-6 pt-0 mt-6" :loading="loading">
      <div class="text-center pt-6">
        <img src="@/assets/svg/Logo.svg" class="welcome-logo mx-auto" />
        <h1 class="mt-2">{{subtitle}}</h1>
        <h3 class="mt-2 welcome-subtitle">
          Forge is the fastest possible way <br/> to host your website.
        </h3>
      </div>

      <slot />
    </v-card>
    <v-card class="auth-container modal_window mx-auto pa-6 mt-6" :loading="loading" v-if="type == 0">
      <div align="center" style="font-size: 16px;">
        Don't have an Account yet?
        <router-link
          to="/registration"
          class="logInLink text-center mt-4"
          :class="{ 'link-disabled': loading }"
          style="{}"
        >
          Create an Account
        </router-link>
      </div>
    </v-card>

    <v-card class="auth-container modal_window mx-auto pa-6 mt-6" :loading="loading" v-if="type == 1">
      <div align="center" style="font-size: 16px;">
        Already have an account? 
        <router-link
          to="/login"
          class="logInLink text-center mt-4"
          :class="{'link-disabled': loading}"
        >
          <span class="ml-2"> Log in </span>
        </router-link>
      </div>
    </v-card>

    <v-card class="auth-container modal_window mx-auto pa-6 mt-6" :loading="loading" v-if="type == 2">
      <div align="center" style="font-size: 16px;">
        Return to
        <router-link
          to="/login"
          class="logInLink text-center mt-4"
          :class="{'link-disabled': loading}"
        >
          <span class="ml-2"> Log in </span>
        </router-link>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "ParentAuthForm",
    props: {
      subtitle: String,
      loading: Boolean,
      type: Number
    }
  }
</script>

<style scoped lang="scss">
  .auth-wrapper {
    background-color: #040D21;
    min-height: 100vh;
    width: 100%;
    background-image: url('~@/assets/sparks-fly-black1.png');
    background-size: 100% 50%;
    background-position: bottom;
    background-repeat: no-repeat;
    padding: 1px 0; // Prevent margin collapse
  }

  .auth-container {
    max-width: 450px;
  }

  .logInLink {
    text-decoration: unset;
    color: #ff7e21;

    .span {
      cursor: pointer;
    }
  }

  .welcome-logo {
    height: 32px;
    width: 120px;
  }

  .welcome-subtitle {
    color: $N28;
    font-family: 'Instrument Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
  }
</style>