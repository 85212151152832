<template>
  <div class="form-creation">
    <v-container>
      <v-row align="center">
        <v-col cols="auto">
          <h2 class="">Forge Formsmith</h2>
        </v-col>
        <v-col cols="auto">
          <v-chip class="beta-badge" color="primary darken-1" text-color="black" label>
            Beta
          </v-chip>
        </v-col>
        <v-col cols="auto">
          <a href="https://docs.getforge.com/guides/formsmith-ai--beta" target="_blank" class="SiteForms__link">
            ?
          </a>
        </v-col>
      </v-row>
    </v-container>
    <p class="bot-message">What kind of form would you like to create today?</p>
    <div class="form-group">
      <v-text-field v-model="formDescription" label="" placeholder="A simple contact form for my business website..."
        filled required prepend-inner-icon="mdi-sparkles"
        :append-outer-icon="loadingFormAI ? 'mdi-loading mdi-spin' : 'mdi-send'"
        @click:append-outer="generateFormMarkup"
        @keyup.enter="generateFormMarkup"></v-text-field>
    </div>

    <div class="sample-prompts">
      <v-row>
        <v-col cols="12" md="6" lg="3" v-for="prompt in samplePrompts" :key="prompt.text">
          <v-card max-width="260" min-height="160" @click="selectPrompt(prompt.text)" class="prompt-card" rounded>
            <v-card-title>{{ prompt.title }}</v-card-title>
            <v-card-text>{{ prompt.description }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div v-if="result" class="result-block">
      <p>{{ result.text }}</p>
      <v-tabs v-model="activeTab">
        <v-tab>HTML</v-tab>
        <v-tab>CSS</v-tab>
        <v-tab>Preview</v-tab>

        <v-tab-item>
          <div class="codemirror-container">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="copyToClipboard" :loading="copyLoading"
                  class="copy-button">
                  <v-icon>mdi-clipboard</v-icon>
                </v-btn>
              </template>
              <span>Copy HTML to Clipboard</span>
            </v-tooltip>
            <codemirror v-model="result.markup" :options="htmlOptions" />
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="codemirror-container">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="copyToClipboard" :loading="copyLoading"
                  class="copy-button">
                  <v-icon>mdi-clipboard</v-icon>
                </v-btn>
              </template>
              <span>Copy CSS to Clipboard</span>
            </v-tooltip>
            <codemirror v-model="result.style" :options="cssOptions" />
          </div>
        </v-tab-item>

        <v-tab-item>
          <SiteFormPreview :markup="result.markup" :style="result.style || ''" />
        </v-tab-item>
      </v-tabs>
      <v-row>
        <v-col>
          <v-alert type="info" dense>
            Disclaimer: AI can make mistakes. Please check important information
            <v-btn text href="https://doc.getforge.com/docs/forge-ai" target="_blank">here</v-btn>.
          </v-alert>
        </v-col>
      </v-row>
    </div>

    <v-snackbar v-model="showSnackbar" :timeout="3000" bottom right>
      Code copied to clipboard
    </v-snackbar>
  </div>
</template>

<script>
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/htmlmixed/htmlmixed.js";
import "codemirror/mode/css/css.js";
import "codemirror/theme/base16-dark.css";
import SiteFormPreview from "./SiteFormPreview.vue";

export default {
  name: "SiteFormCreation",
  components: {
    codemirror,
    SiteFormPreview,
  },
  data() {
    return {
      formDescription: "",
      result: null,
      loadingFormAI: false,
      copyLoading: false,
      showSnackbar: false,
      activeTab: 0,
      samplePrompts: [
        {
          title: "Simple Signup Form",
          description:
            "Create a simple signup form with name, email, and password fields.",
          text:
            "Create a simple signup form with name, email, and password fields.",
        },
        {
          title: "NPS Survey",
          description:
            "Create a Net Promoter Score survey with a rating scale from 1 to 10.",
          text:
            "Create a Net Promoter Score survey with a rating scale from 1 to 10.",
        },
        {
          title: "Contact Form",
          description:
            "Create a contact form with name, email, subject, and message fields.",
          text:
            "Create a contact form with name, email, subject, and message fields.",
        },
        {
          title: "Event Registration Form",
          description:
            "Create an event registration form with fields for name, email, and event details.",
          text:
            "Create an event registration form with fields for name, email, and event details.",
        },
      ],
      htmlOptions: {
        tabSize: 2,
        mode: "text/html",
        theme: "base16-dark",
        lineNumbers: true,
      },
      cssOptions: {
        tabSize: 2,
        mode: "css",
        theme: "base16-dark",
        lineNumbers: true,
      },
    };
  },
  methods: {
    generateFormMarkup() {
      this.loadingFormAI = true;

      const prompt = `
You are a senior front end developer specializing in web form markup.
All forms and form elements must have the property 'data-forge-name' with a suitable value for integration with getforge.com hosted forms.
Example:
<form data-forge-name="Contact Form">
  <input type="text" data-forge-name="Name" />
  <input type="email" data-forge-name="Email" />
  <textarea data-forge-name="Message"></textarea>
  <button type="submit">Submit</button>
</form>

Requirements:
- Use correct HTML tags and attributes for accessibility, security, usability, and best practices.
- Prefer selects over text inputs for dropdowns.
- Use radio buttons for single choice options.
- Use checkboxes for multiple choice options.
- For NPS, CSAT, and similar surveys, use a 1-10 rating scale via a select dropdown.

Response format:
Respond with only a clean, valid JSON object '{ "text": "", "markup": "", "style": "" }'.
- The text key will contain general notes and guidance about the form and potential next steps.
- The markup key will contain only the HTML form markup in plain text.
- Include line spaces in the html and css result.
- The style key will contain CSS needed for a nice form presentation or according to user input.

Examples:
Bad Example:
\`\`\`json
{
  "text": "This is a form",
  "markup": "<form></form>",
  "style": "valid css here"
}
\`\`\`

Good Example:
{
  "text": "This is a form",
  "markup": "<form data-forge-name='Contact Form'>\\n  <input type='text' data-forge-name='Name' />\\n  <input type='email' data-forge-name='Email' />\\n  <textarea data-forge-name='Message'></textarea>\\n  <button type='submit'>Submit</button>\\n</form>",
  "style": "form {\\n    max-width: 400px;\\n    margin: 0 auto;\\n    padding: 20px;\\n    border: 1px solid #ccc;\\n    border-radius: 5px;\\n  }\\n  label {\\n    display: block;\\n    margin-bottom: 5px;\\n  }\\n  input, button {\\n    width: 100%;\\n    padding: 10px;\\n    margin-bottom: 10px;\\n    border: 1px solid #ccc;\\n    border-radius: 5px;\\n  }\\n  button {\\n    background-color: #007bff;\\n    color: #fff;\\n    cursor: pointer;\\n  }"
}

If the user input is not related to form or survey generation, respond in the text field with a polite decline and advise to ask about form generation.

Good example user input: "I need a form for my website to collect user feedback.", "I need a survey form for my customers.", "Create a simple signup form with name, email, and password fields."
Bad example user input: "I need help", "what is the weather in New York?"

User input: ${this.formDescription}
`;

      this.api.Openai.completions({
        chat: {
          model: "gpt-3.5-turbo-0125",
          response_json: true,
          prompt: prompt,
        },
      })
        .then((res) => {
          try {
            const messageContent = res.body.choices[0].message.content;
            this.result = this.parseJsonResponse(messageContent);
            this.loadingFormAI = false;
            this.$emit("formCreated");
          } catch (e) {
            console.error("Error parsing form markup", e);
            this.loadingFormAI = false;
          }
        })
        .catch((e) => {
          console.error("Error generating form markup", e);
          this.loadingFormAI = false;
        });
    },
    parseJsonResponse(response) {
      // Remove the code block delimiters if they exist
      const cleanedResponse = response.replace(/```json\n|```/g, "");

      // Replace Unicode characters with their actual symbols
      let cleanedContent = cleanedResponse
        .replace(/\\u003c/g, "<")
        .replace(/\\u003e/g, ">")
        .replace(/\\n/g, "\\n") // Keep newlines escaped
        .replace(/\\"/g, '\\"') // Ensure double quotes remain escaped
        .replace(/\\'/g, "'");

      // Replace any escaped double quotes that may cause issues
      cleanedContent = cleanedContent.replace(/\\\\"/g, '\\"');

      // Ensure proper escaping of special characters
      cleanedContent = cleanedContent.replace(/\\\\/g, "\\");

      // Parse the cleaned JSON string
      return JSON.parse(cleanedContent);
    },

    copyToClipboard() {
      this.copyLoading = true;
      let contentToCopy = "";
      if (this.activeTab === 0) {
        contentToCopy = this.result.markup;
      } else if (this.activeTab === 1) {
        contentToCopy = this.result.style;
      }

      navigator.clipboard
        .writeText(contentToCopy)
        .then(() => {
          this.copyLoading = false;
          this.showSnackbar = true;
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          this.copyLoading = false;
        });
    },
    selectPrompt(prompt) {
      this.formDescription = prompt;
    },
  },
};
</script>

<style scoped>
.form-creation {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 16px;
}

.result-block {
  margin-top: 20px;
  background-color: #292929;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.codemirror-container {
  position: relative;
}

.copy-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  background-color: #ffffff;
}

.copy-button span i {
  color: #151515 !important;
}

.copy-button .mdi-loading {
  color: #151515 !important;
}

.CodeMirror {
  height: auto;
  max-height: 400px;
  background-color: #151515;
  color: #fff;
}

.mdi-loading {
  animation: spin 1s linear infinite;
}

.bot-message {
  background-color: #e0e0e0;
  color: #333;
  padding: 10px 15px;
  border-radius: 15px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-block;
}

.bot-message::before {
  content: "";
  position: absolute;
  left: 10px;
  bottom: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid #e0e0e0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.sample-prompts {
  margin-bottom: 20px;
}

.prompt-card {
  cursor: pointer;
  transition: transform 0.2s;
  background: rgba(255, 255, 255, 0.1);
}

.prompt-card:hover {
  transform: scale(1.05);
}

.prompt-card .v-card__title {
  font-size: 14px !important;
  font-weight: 900;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
