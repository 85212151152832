<template>
  <v-navigation-drawer
    class="the-navigation"
    app
    v-model="isNavigationShow"
    :mini-variant="isNavDrawerMiniVariant"
    :mini-variant-width="96"
    :mobile-breakpoint="600"
    dark
    :width="288"
  >
    <v-dialog
      v-model="changeOwnerFailed"
      max-width="400"
    >
      <v-card class="changeOwnerFailedCard">
        <v-card-title >
          Change site owner
        </v-card-title>
        <v-card-text> {{changeOwnerMessage}} </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="y1"
            @click="changeOwnerFailed = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="the-navigation__header">
      <v-icon
        class="the-navigation__toggle-btn d-none d-sm-block"
        :class="{ _mini: isNavDrawerMiniVariant }"
        color="#A4A4AE"
        @click="changeDrawerState"
      >
        {{ isNavDrawerMiniVariant ? "mdi-chevron-right" : "mdi-chevron-left" }}
      </v-icon>
      <v-list-item>
        <router-link to="/">
          <the-icon
            class="the-navigation__logo"
            :icon="isNavDrawerMiniVariant ? publisherRoute ? 'blue-logo-small' : 'logo-small' : publisherRoute ? 'blue-logo' : 'logo'"
          />
        </router-link>
      </v-list-item>
    </div>

    <v-divider class="the-navigation__divider" />
    <navigation-publisher-list v-if="publisherRoute" />
    <navigation-client-list v-else />

    <div
      class="the-navigation__footer"
      :class="{ _small: isNavDrawerMiniVariant }"
    >
      <v-menu location="top" content-class="the-navigation__footer_menu" v-if="!publisherRoute">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            block
            dark
            v-bind="attrs"
            v-on='on'
            v-if="canManage"
            class="the-navigation__footer-btn"
            :class="{ '_mini': isNavDrawerMiniVariant }"
          >
            <span v-if="!isNavDrawerMiniVariant">  
              <v-icon class="the-navigation__nav-icon mr-2">
                mdi-plus
              </v-icon> Create new
            </span>
            <span v-else>
              <v-icon class="the-navigation__nav-icon">
                mdi-plus
              </v-icon>
            </span>
          </v-btn>
          <v-btn
            v-else
            block
            color="primary"
            @click="goToUpgrade"
            class="the-navigation__footer-btn"
            :class="{ '_mini': isNavDrawerMiniVariant }"
          >
            <span v-if="!isNavDrawerMiniVariant">
              <v-icon class="the-navigation__nav-icon mr-2" color="y1">
                mdi-arrow-up-bold-circle-outline
              </v-icon> Upgrade Plan
            </span>
            <span v-else>
              <v-icon class="the-navigation__nav-icon footer_icon _small" color="y1">
                mdi-arrow-up-bold-circle-outline
              </v-icon>
            </span>
          </v-btn>
        </template>
        <v-list class="footer_menu_list">
          <v-list-item>
            <v-list-item-title>
              <v-btn
                block
                dark
                @click="projectsAvailable ? createProject() : showUpgradeDialog('projects')"
                class="the-navigation__footer-menu-btn"
              >
                <v-icon 
                  v-if="!projectsAvailable"
                  class="the-navigation__nav-icon footer_menu_icon mr-2" 
                  color="y1"
                >
                  mdi-arrow-up-bold-circle-outline
                </v-icon>
                <the-icon
                  v-else
                  icon="fill"
                  class="the-navigation__nav-icon footer_menu_icon mr-2"
                />
                <span class="the-navigation__footer-menu-btn-text" :class="{ '_upgrade': !projectsAvailable }">
                  {{ projectsAvailable ? 'Project' : 'Upgrade for More Projects' }}
                </span>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title> 
              <v-btn
                block
                @click="siteLimitReached ? showUpgradeDialog('sites') : createSite()"
                class="the-navigation__footer-menu-btn"
              >
                <v-icon 
                  v-if="siteLimitReached"
                  class="the-navigation__nav-icon footer_menu_icon mr-2"
                  color="y1"
                >
                  mdi-arrow-up-bold-circle-outline
                </v-icon>
                <v-icon
                  v-else
                  class="the-navigation__nav-icon footer_menu_icon mr-2"
                >
                  mdi-web
                </v-icon>
                <span class="the-navigation__footer-menu-btn-text" :class="{ '_upgrade': siteLimitReached }">
                  {{ siteLimitReached ? 'Upgrade for More Sites' : 'Website' }}
                </span>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
  
      <v-btn
        block
        color="n5"
        @click="togglePublisherSite()"
        class="the-navigation__footer-btn"
        :class="{ '_mini': isNavDrawerMiniVariant }"
        v-if="currentPublisherInfo && currentPublisherInfo.id"
      >
        <span v-if="!isNavDrawerMiniVariant">
          <span v-if="publisherRoute">To Developer Dashboard</span>
          <span v-else>To Publisher Dashboard</span>
        </span>
        <span v-else>
          <span v-if="publisherRoute">C</span>
          <span v-else>P</span>
        </span>
      </v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Avatar from 'vue-avatar';
import _ from 'lodash';
import moment from 'moment'
import {Site as SiteResource} from "@/resources/forge";
import {
  MODAL_CREATE_PROJECT,
  MODAL_CREATE_SITE,
  MODAL_ALERT,
  MODAL_CREATE_ORGANISATION,
} from "@/components/Modals";
import { MODAL_NEW_VERSION_DEPLOYED } from "@/components/Modals";
import NavigationClientList from "@/components/TheNavigation/NavigationClientList";
import NavigationPublisherList from "@/components/TheNavigation/NavigationPublisherList";
import NavigationSites from "@/components/TheNavigation/NavigationSites";


export default {
  name: "TheNavigation",
  components: { NavigationClientList, NavigationPublisherList, NavigationSites, NavigationSites, Avatar },
  data() {
    let activeOrg = this.$store.state.organisations.current ? this.$store.state.organisations.current.id : 0;
    return {
      activeOrganisation: activeOrg,
      addOrganisation: {
        id: -1,
        name: "Add Organisation"
      },
      changeOwnerFailed: false,
      changeOwnerMessage: "",
      listGroups: {},
      changes: [],
      projectsArray: [],
      sitesArray: [],
      isNavigationShow: !this.isMobile,
    };
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.current,
      projects: ({ projects }) => projects.list,
      sites: ({ sites }) => sites.list,
      organisations: ({ organisations }) => organisations.list,
      isNavDrawerMiniVariant: ({ application }) =>
        application.isNavDrawerMiniVariant,
      currentPublisherInfo: ({ publisher_infos }) => publisher_infos.current,
      showingMobileNav: ({ application }) => application.showingMobileNav,
      currentOrganisation: ({ organisations }) => organisations.current,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
     sitesUnassigned() {
      return this.productionSites.filter((s) => !s.projectId);
    },
    forgeSites() {
      return this.sitesUnassigned.filter((s) => s.kind == "site" );
    },
    canManage() {
      if (this.$store.state.organisations.current.id == 0)
        return true;
      return this.$store.state.organisations.current.subscription_active;
    },
    forgePlugins() {
      return this.sitesUnassigned.filter((s) => s.kind == "plugin" );
    },
    projectsAvailable() {
      let maxProjects = this.$store.state.organisations.current.id == 0 ? 
        this.$store.state.user.current.maximum_number_of_projects :
        this.$store.state.organisations.current.maximum_projects;
      return this.projects.length < maxProjects;
    },
    siteLimitReached() {
      if (this.$store.state.organisations.current.id == 0)
        return this.user.maximum_number_of_sites <= this.productionSites.length
      else
        return this.$store.state.organisations.current.maximum_sites <= this.$store.state.organisations.current.sites_count;
    },
    objMaxSites() {
      if (this.$store.state.organisations.current.id == 0)
        return this.user.maximum_number_of_sites
      else
        return this.$store.state.organisations.current.maximum_sites;
    },
    objSites() {
      if (this.$store.state.organisations.current.id == 0)
        return this.user.sites_count
      else
        return this.$store.state.organisations.current.sites_count;
    },
    productionSites() {
      return this.sites.filter((s) => s.mode == 'production')
    },
    publisherRoute() {
      return (this.$route.meta.publisherRoute === true);
    },
    sitesUnassigned() {
      return this.productionSites.filter((s) => !s.projectId);
    },
    forgeSites() {
      return this.sitesUnassigned.filter((s) => s.kind == "site" );
    },
    forgePlugins() {
      return this.sitesUnassigned.filter((s) => s.kind == "plugin" );
    },
    isOrgAdmin() {
      console.log('Current Organisation:', this.currentOrganisation);
      console.log('Organisation Role:', this.currentOrganisation?.role);
      
      return this.currentOrganisation && 
             this.currentOrganisation.id !== 0 && 
             (this.currentOrganisation.role === 'admin' || 
              this.currentOrganisation.role === 'owner');
    },
    upgradeLink() {
      if (this.currentOrganisation && this.currentOrganisation.id !== 0) {
        return `/organisation/${this.currentOrganisation.id}/organisation_billing`;
      }
      return '/profile/billing';
    }
  },
  watch: {
    drawer() {
      if (this.drawer) this.$root.$emit("updateNavigation");
    },
    "$store.state.sites.list": {
      handler (newVal, oldVal) {
        this.sitesArray = this.filteredSitesArray(_.cloneDeep(newVal));
        this.loading = false;
      },
      deep: true
    },
    "$store.state.projects.list": {
      handler (newVal, oldVal) {
        this.projectsArray = _.cloneDeep(newVal);
        this.sitesArray = this.filteredSitesArray(_.cloneDeep(this.$store.state.sites.list));
        this.loading = false;
      },
      deep: true
    },
    "$route.params.id"() {
      if (this.$route.matched[0].name == "site" && this.activeSite)
        this.$set(this.listGroups, this.activeSite.projectId, true);
      if (this.$route.matched[0].name == "project" && this.activeProject)
        this.$set(this.listGroups, this.activeProject.id, true);
    },
    isNavigationShow() {
      if (this.isMobile)
        this.$store.commit("application/setShowingMobileNav", this.isNavigationShow);
    },
    showingMobileNav() {
      if (this.isMobile) this.isNavigationShow = this.showingMobileNav;
    }
  },
  methods: {
    ...mapMutations("application", ["toggleisNavDrawerMiniVariant"]),
    ...mapActions("application", ["logOut"]),
    isProjectActive(project) {
      if (!this.$route.matched[0]) return false;
      if (this.$route.matched[0].name == "site")
        return this.activeSite.projectId == project.id;
      if (this.$route.matched[0].name == "project")
        return this.activeProject.id == project.id;
    },
    openProject(project) {
      this.$router.push("/project/" + project.id);
    },
    handleChangePosition(event, list, project) {
      if (event.added) {
        let { newIndex, element } = event.added;
        let validated = true;
        let validatedMessage = "You should be the owner of a site and a project";
        let validatedChangeOwner = false;
        if (!project && !element)
          validated = false;
        else if (project && (project.user_id != this.user.id || element.userId != this.user.id))
          validated = false;
        else if (!project && (this.user.id != element.userId))
          validated = false;
        if (project && element && (project.user_id != this.user.id && element.userId == this.user.id)){
          validatedChangeOwner = true;
          validated = true;
        }
        if (validated && !validatedChangeOwner) {
          const moveToId = project ? project.id : null;
          let moveToName = project ? ' to ' + project.name : ' out from project'
          this.$store.commit("application/openModal", {
            component: MODAL_ALERT,
            props: {
              title: "Site Move",
              text: "Are you want to move " + element.url + moveToName+  " ?" ,
              actionBtnLabel: "Move",
              showCancelBtn: true,
              action: () => {
                this.$store.dispatch("sites/moveSite", {id: element.id, project_id: moveToId})
                  .then(() => {return true;});
              },
            },
          });
        }
        else if (validated && validatedChangeOwner) {
          let app_url = location.protocol + '//' + location.host;
          let success = null;
          let params = {
            new_owner_id: project.user_id,
            app_url: app_url,
            project_id: project.id
          }
          this.$store.commit("application/openModal", {
            component: MODAL_ALERT,
            props: {
              title: "Change site owner to project owner",
              text: "Are you sure? To project owner will be send an email to confirm change.",
              actionBtnLabel: "Change",
              showCancelBtn: true,
              action: () => {
                SiteResource.changeSiteOwner({id: element.id}, params )
                .catch((e) => {
                  setTimeout(() => {
                    this.changeOwnerMessage = e.body.message;
                    this.changeOwnerFailed = true;
                  }, 1800)
                })
              },
            },
          });
        }
        else
        {
          this.$store.commit("application/openModal", {
            component: MODAL_ALERT,
            props: {
              title: "Site Move",
              text: validatedMessage,
              actionBtnLabel: "Close",
            },
          });
        }
      }
      this.projectsArray = _.cloneDeep(this.$store.state.projects.list);
      this.sitesArray = this.filteredSitesArray(_.cloneDeep(this.$store.state.sites.list));
    },
    filteredObjects(sites) {
      sites = sites.filter((site) => site !== undefined )
      let prodSites = sites.filter((s) => s.mode == 'production')
      prodSites = _.uniqBy(prodSites, 'id');
      const itemsOnTop = prodSites.filter((site) => site.kind == 'site')
      const itemsOnBottom = prodSites.filter((site) => site.kind == 'plugin')
      return [...itemsOnTop, ...itemsOnBottom];
    },
    filteredSitesArray(sites) {
      sites = sites.filter((s) => s.mode == 'production' && !s.projectId);
      const itemsOnTop = sites.filter((site) => site.kind == 'site')
      const itemsOnBottom = sites.filter((site) => site.kind == 'plugin')
      return [...itemsOnTop, ...itemsOnBottom];
    },
    initials(username) {
      username[0].toUpperCase();
    },
    openSiteUrl(site) {
      window.open("http://" + site.url, "_blank");
    },
    createOrganisation() {
      this.$store.commit("application/openModal", {
        component: MODAL_CREATE_ORGANISATION,
      });
    },
    async setActiveOrganisation(id) {
      if (id == -1){
        this.createOrganisation();
        this.activeOrganisation = this.organisations.find(org => org.id == this.$store.state.organisations.current.id);
      }
      else {
        await this.$store.dispatch("organisations/setCurrent", id ,{ root: true });
        location = "/"; 
      }
    },
    openOrganisationSettings() {
      this.$router.push(`/organisation/${this.organisationId}`);
    },
    createSite(project_id = null) {
      this.$store.commit("application/openModal", {
        component: MODAL_CREATE_SITE,
        props: {
          projectId: project_id
        }
      });
    },
    createProject() {
      this.$store.commit("application/openModal", {
        component: MODAL_CREATE_PROJECT,
      });
    },
    changeDrawerState() {
      this.toggleisNavDrawerMiniVariant(!this.navDrawer);
    },
    togglePublisherSite() {
      if (this.publisherRoute) this.$router.push({ name: "dashboard"}); else this.$router.push({ name: 'publisher_dashboard' });
    },
    goToUpgrade() {
      if (this.currentOrganisation && this.currentOrganisation.id !== 0) {
        if (this.isOrgAdmin) {
          this.$router.push(this.upgradeLink);
        } else {
          this.$store.commit("application/openModal", {
            component: MODAL_ALERT,
            props: {
              title: "Plan Expired",
              text: "Contact your Administrator",
              actionBtnLabel: "Close",
            },
          });
        }
      } else {
        this.$router.push(this.upgradeLink);
      }
    },
    showUpgradeDialog(type) {
      this.goToUpgrade();
    }
  },
  mounted() {
    this.projectsArray = _.cloneDeep(this.$store.state.projects.list);
    this.sitesArray = this.filteredSitesArray(_.cloneDeep(this.$store.state.sites.list));

    let channel = this.$pusher.subscribe(
      this.$store.state.user.current.pusherChannel
    );
    channel.bind("log", (data) => {
      this.$store.dispatch("versions/addMessageToVersion", data);
    });

    channel.bind("site_update", (data) => {
      if (data.site_app && data.site_app.all_app_id == 2) {
        if (
          this.$store.state.site_app.chiselApp == null ||
          this.$store.state.site_app.chiselApp.id !=
            data.site_app.chisel_config.id
        )
          return;
        this.$store.dispatch("site_app/updateDataInList", data.site_app);
      }
      else if (!data.site_app) {
        this.$store.dispatch("sites/updateDataInList", 
        {
          id: data.site.id,
          thumb_image_url: data.site.thumb_image_url,
          kind: data.site.kind,
          image: data.site.favicon_url,
          projectId: data.site.project_id,
          updated_at: data.site.updated_at,
          publisherInfo: data.site.publisher_info
        })
        if (this.$store.state.sites.previousKind && this.$store.state.sites.active && data.site.id === this.$store.state.sites.active.id) {
          this.$store.commit("application/openModal", 
            {
              component: MODAL_NEW_VERSION_DEPLOYED,
              props: {
                siteName: this.$store.state.sites.active.site_name,
                siteType: this.$store.state.sites.previousKind,
                deployed: data.site.kind
              }
            }
          );
          this.$store.commit('sites/setPreviousKind', null)
        }
      }
    });

    channel.bind("user_update", (data) => {
      this.$store.commit("user/update", data.user);
    });

    channel.bind("comment_added", (data) => {
      if (this.activeSite && this.activeSite.id == data.comment.site_id || 
        this.$store.state.parse_cloud.current && this.$store.state.parse_cloud.current.id == data.comment.custom_code_id)
        this.$store.dispatch("comments/commentAdded", data.comment)
    });

    channel.bind("version_unread_comments", (data) => {
      this.$store.commit('versions/updateUnreadComments', data);
    })

    channel.bind('notification_added', (data) => {
      if (this.$store.state.organisations.current && (this.$store.state.organisations.current.id == 0 && data.notification.organisation_id == null ||
       this.$store.state.organisations.current.id == data.notification.organisation_id) )
        this.$store.commit('notifications/addNotification', data.notification);
    })

    channel.bind('version_removed', (data) => {
      this.$store.dispatch('versions/clearFromSocket', data);
    })

    channel.bind('comment_removed', (data) => {
      this.$store.commit("comments/delete", data.comment_id)
    })

    channel.bind("version_update", (data) => {
      let version = data.version;
      if (version.chisel_data) {
        this.$store.commit("site_app/updateChiselPercent", version);
      } else {
        if (version.diff == null) delete version.diff;
        this.$store.dispatch("versions/update", version);
        // Update currentVersionStatus of activeSite when the current version changed
        if (this.activeSite && this.activeSite.currentVersionId === version.id) {
          this.$store.commit('sites/setCurrentVersionStatus', version.deploy_status);
        }
        this.$store.commit('sites/updateSlug');
      }
    });

    channel.bind("parse_deployed", (data) => {
      if (this.$store.state.site_app.parseSetting == null) return;
      if (this.$store.state.site_app.parseSetting.id == data.parse_setting_id)
        this.$store.commit(
          "site_app/setDeployedParseSetting",
          {site_app_id: data.site_app_id, cloud_code_enabled: data.cloud_code_enabled }
        );
    });

    channel.bind("log", (data) => {
      if (data.parse_server) {
        if (this.$store.state.site_app.parseSetting == null) return;
        this.$store.commit("site_app/setServiceMessages", {
          message: data.message,
          clear: data.clear,
          id: data.version_id,
        });
      }
      if (data.chisel_cms == 1) {
        let message = {
          message: data.message,
          time: data.time,
          status: data.status,
        };
        let key_name = `version_${data.version_id}`;
        if (localStorage.getItem(key_name) == null)
          localStorage.setItem(key_name, JSON.stringify([message]));
        else {
          let json_data = JSON.parse(localStorage.getItem(key_name));
          let messages = [...json_data, message];
          localStorage.setItem(key_name, JSON.stringify(messages));
        }
        this.$store.commit("site_app/setChiselMessages", data.version_id);
      }
    });
  },
};
</script>
<style lang="scss">
$style: the-navigation;
.mdi-plus:hover {
  color: white !important;
}

.v-text-field>.v-input__control>.v-input__slot:after {
  border-style: unset !important;
}

.v-list-item--active .org_select {
  width: 100%;
}

.v-list-item__icon {
  margin-right: 5px !important;
}
.v-list-group--sub-group .v-list-group__header {
  padding-left: 0px !important;
}

.changeOwnerFailedCard {
  background: rgba(37, 36, 55, 0.9) !important;
  border: 1px solid #2e2c42;
  box-shadow: 0px 20px 60px rgb(0 0 0 / 25%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 8px !important;
  padding: 17px 0px 0;
  width: 100%;

  .v-card__title {
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #fff !important;
  }
  .v-card__text {
    display: block;
    font-size: 14px;
    color: #a4a4ae;
    font-weight: 400;
    margin-bottom: 4px;
  }
  .v-card__actions {
    button {
      margin-bottom: 4px;
    }
  }
}

.#{$style} {
  max-height: none !important;
  padding: 82px 24px 191px;
  &.v-navigation-drawer--mini-variant {
    overflow: initial;
    padding: 82px 0px 191px;
  }
  &__org_select{

    .v-input__icon {
      margin-top: 4px;
    }

    &._mini {
      max-width: 20px;
      padding: 0 !important;
      max-height: 20px;
      .v-input__slot {
        padding-left: 2px !important;
        max-height: 53px !important;
      }
    }
  }
  ._plugin{
    padding-left: 5px;
  }
  &__plugin_img{
    width: 16px;
    height: 16px;
    margin-left: -5px;
    vertical-align: middle;
    margin-right: 7px;
  }
  &__img_logo {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
  }
  &__item_name {
    margin-left: 10px;
    text-overflow: ellipsis;
    max-width: 117px;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;
  }
  &__project-icon.v-list-item__icon {
    margin-right: 15px !important;
  }
  &__project_title_content {
    max-width: 114px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__project_sites_list {
    max-width: 139px;
  }

  &.v-navigation-drawer {
    background-color: $dark-bg-color;
    .v-navigation-drawer__content {
      &::-webkit-scrollbar {
        width: 3px;
        background: #1f1e31;
      }
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
    }
  }
  &__header {
    position: absolute;
    top: 16px;
    left: 6px;
    right: 0;
  }
  &__icon {
    cursor: pointer;
    transition: filter 0.2s ease;
    &:hover {
      filter: brightness(1.2);
    }
  }
  &__toggle-btn.v-icon {
    position: absolute;
    right: 22px;
    top: 13px;
    border: 1px solid $N5;
    border-radius: 4px;
    z-index: 1;
    background-color: $dark-bg-color;
    &._mini {
      right: -12px;
    }
    &:focus::after {
      content: none;
    }
    &:hover {
      border-color: $N3;
    }
  }
  &__list {
    padding: 0;
  }
  &__divider {
    margin: 0 0 0px;
    &.v-divider {
      background-color: $N5;
    }
  }
  &__list-item {
    margin-bottom: 20px;
  }
  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 24px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    
    &._small {
      padding: 0 30px 40px;
    }
  }
  &__footer-btn {
    background: $Y1 !important;
    width: 100%;
    
    &:focus {
      background: #9B4019 !important;
      &:before {
        opacity: 0 !important;
      }
    }
    
    &.v-btn {
      margin-bottom: 20px;
      padding: 0 !important;
      height: 48px !important;
      
      &._mini {
        width: 48px !important;
        min-width: 48px !important;
        height: 48px !important;
        display: flex;
        justify-content: center;
        align-items: center;

        .v-icon {
          margin: 0;
        }
      }
    }
  }
  &__footer-link {
    &.v-size--default {
      font-size: 16px;
      padding: 0 !important;
    }
    font-weight: 600;
  }
  &__footer-icon {
    margin-right: 14px;
    &._margin-less {
      margin-right: 0;
    }
  }
  &__nav-icon {
    fill: white;
  }
  &__nav-content {
    color: $N2;
    font-weight: 500;
    font-size: $H14;
    white-space: nowrap;
  }
  &__list-item {
    &._active {
      color: $white;
      .#{$style}__nav-icon {
        fill: $B1;
      }
      .#{$style}__nav-content {
        color: $B1;
      }
      &:before {
        content: none;
      }
    }
  }
  &__projects {
    &.v-list-group--sub-group.v-list-group--active .v-list-item__icon.v-list-group__header__prepend-icon .v-icon {
      transform: rotate(90deg) !important;
    }
    &._small {
      padding: 0;
      display: block;
      margin-left: 15px;
      .v-list-group__items {
        display: none;
      }
    }
    &
      .v-list-group__header
      .v-list-item__icon.v-list-group__header__append-icon {
      display: none;
    }
    & .#{$style}__head {
      color: $N2 !important;
      .v-icon {
        color: inherit;
      }
    }
    &._active {
      & .#{$style}__head {
        color: $white !important;
      }
    }
    & .v-list-item {
      padding: 0;
    }
  }
  &__project {
    &._plugin {
      margin-left: 5px;
    }
    &.v-list-item {
      padding-left: 30px !important;
      padding: 0 8px;
    }
    &.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: $N2;
    }
    &.v-list-item--active {
      color: $white;
      & .#{$style}__sub-list-item-name {
        color: $white;
      }
      .#{$style}__sub-arrow {
        color: white !important; 
      }
    }
  }
  &__sub-list-name {
    &.v-list-item {
      padding-left: 0 8px;
    }
  }
  &__sub-list-item-name {
    font-weight: 500;
    font-size: $H12;
    color: $N2;
  }
  &__sub-arrow {
    &.theme--dark.v-btn.v-btn--icon {
      color: #a4a4ae;
    }
    &:hover {
      color: white !important;
    }
  }
  &__dot {
    display: flex;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $G1;
    margin-right: 15px;
  }

  &__footer_menu {
    bottom: 120px;
    top: unset !important;
    min-width: 159px !important;

    .footer_menu_list {
      height: 120px;
      background: $N4;
      border: 1px solid #2E2C42;
      box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.25) !important;
      backdrop-filter: blur(5px);

      .the-navigation__footer-menu-btn {
        background: $N5;
        margin: 0;
        justify-content: left;
        padding-left: 16px;
      }
      .footer_menu_icon {
        width: 20px;
        font-size: 20px;
        height: 20px;
      }
    }

  }

  &__footer-menu-btn-text {
    font-size: 12px !important;
    font-weight: 500;
    color: white;
    
    &._upgrade {
      color: $Y1;
    }
  }
}
$style2: navigation-sites;
.#{$style2} {
  padding-left: 4px;
  &._small {
    padding-left: 0;
    .#{$style2}__plugin_img {
      margin-left: 17px !important;
    }
  }
  & &__nav-icon {
    color: $N2;
  }
  .__plugin {
    padding-left: 5px;
  }
  &__plugin_img {
    width: 16px;
    vertical-align: middle;
    height: 16px;
    margin-left: 7px;
    margin-right: 7px;
  }
  &__dot {
    display: flex;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $G1;
    margin-right: 15px;
  }
  &__nav-content {
    color: $N2;
    font-weight: 500;
    font-size: .8125rem;
    white-space: nowrap;
    letter-spacing: normal;
  }
  &__list-item {
    &._active {
      color: $white;
      .#{$style2}__nav-icon  {
        color: $white;
      }
      .#{$style}__sub-arrow {
        color: white !important; 
      }
      .#{$style2}__nav-content {
        color: $white;
      }
      &:before {
        content: none;
      }
    }
  }
}

.the-navigation {
  &__footer-btn {
    &.v-btn--disabled {
      opacity: 0.7;
      pointer-events: auto !important;
      cursor: not-allowed;
    }
  }
}

.site-icon-wrapper {
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .site-default-icon {
    color: $N2;
  }
  
  .site-favicon {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}

.status-dot {
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid $dark-bg-color;
  
  &.status-live {
    background-color: $G1;
  }
  
  &.status-error {
    background-color: $R1;
  }
}

.site-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  &._mini {
    display: none;
  }
}
</style>
