export class siteApp {
  id = null;
  site_id = null;
  user_id = null;
  activated = false;
  all_app_id = null;
  restKey = null;
  jsKey = null;

  app = null;

  constructor(origin) {
    if (!origin)
      return;
    this.id = origin.id;
    this.site_id = origin.site_id;
    this.user_id = origin.user_id;
    this.restKey = origin.restkey;
    this.jsKey = origin.jskey;
    this.activated = origin.activated;
    this.all_app_id = origin.all_app_id;
    if (!origin.parse_server) {
      this.app = new AppChiselCMS(origin.chisel_config, this.activated, origin.percent_deployed);
    }
    else{
      this.app = new AppParseServer(origin.parse_config, this.activated, origin);
    }

  }

  getServerObject() {
    return {
      site_id: this.site_id,
      user_id: this.user_id,
      activated: this.activated,
      all_app_id: this.all_app_id,
    };
  }
}

export const APP_PARSE_SERVER = "1";
export const APP_CHISEL_CMS = "2";

export const APP_STATUS = {
  AVAILABLE: 'available',
  COMING_SOON: 'coming_soon'
};

export const APPS_DATA = [
  {
    id: APP_PARSE_SERVER,
    name: "Forge Ignite",
    image: require("@/components/TheSite/SiteApps/tempPics/parse-server.png"),
    serviceType: 'PaaS',
    status: APP_STATUS.AVAILABLE,
    description: "Run your own Parse Server with Forge Ignite. \n A supercharged, open-source backend solution for your sites and plugins.",
    docs: "https://docs.getforge.com/guides/forge-ignite",
    product_page: "https://getforge.com/forge-ignite"
  },
  {
    id: APP_CHISEL_CMS,
    name: "Chisel CMS",
    image: require("@/components/TheSite/SiteApps/tempPics/chisel-app.jpg"),
    serviceType: 'Content Management',
    status: APP_STATUS.AVAILABLE,
    description: "Chisel is an API-first headless CMS running on Parse Server. \n A powerful, free and open-source content management solution",
    docs: "https://docs.getforge.com/guides/chisel-cms",
    product_page: "https://chiselcms.com"
  },
  {
    id: '3',
    name: "Analytics Hub",
    image: require("@/components/TheSite/SiteApps/tempPics/chisel-app.jpg"),
    serviceType: 'Analytics',
    status: APP_STATUS.COMING_SOON,
    description: "Comprehensive analytics and insights for your Forge applications. \n Track usage, performance, and user behavior in real-time.",
    docs: "https://docs.getforge.com/guides/anaytics-hub",
    product_page: "https://getforge.com/analytics"
  },
  {
    id: '4',
    name: "Forge AI",
    image: require("@/components/TheSite/SiteApps/tempPics/chisel-app.jpg"),
    serviceType: 'AI',
    status: APP_STATUS.COMING_SOON,
    description: "Forge AI is an AI-powered assistant for your Forge applications. \n Get instant answers, insights, and recommendations for your sites and plugins.",
    docs: "https://docs.getforge.com/guides/forge-ai",
    product_page: "https://getforge.com/ai"
  },
  {
    id: '5',
    name: "PostHog",
    image: require("@/components/TheSite/SiteApps/tempPics/posthog.jpg"),
    serviceType: 'Analytics',
    status: APP_STATUS.COMING_SOON,
    description: "PostHog is an open-source, full-stack product analytics platform. \n Track usage, performance, and user behavior in real-time.",
    docs: "https://docs.getforge.com/guides/posthog",
    product_page: "https://posthog.com"
  },
  {
    id: '6',
    name: "Google Analytics",
    image: require("@/components/TheSite/SiteApps/tempPics/google-analytics.jpg"),
    serviceType: 'Analytics',
    status: APP_STATUS.COMING_SOON,
    description: "Google Analytics is a free and open-source web analytics service. \n Track usage, performance, and user behavior in real-time.",
    docs: "https://docs.getforge.com/guides/google-analytics",
    product_page: "https://analytics.google.com"
  },
  {
    id: '7',
    name: "Facebook Pixel",
    image: require("@/components/TheSite/SiteApps/tempPics/facebook-pixel.jpg"),
    serviceType: 'Analytics',
    status: APP_STATUS.COMING_SOON,
    description: "Track user behavior on your site and optimize your ads. \n Get insights on your audience and improve your ad performance.",
    docs: "https://docs.getforge.com/guides/facebook-pixel",
    product_page: "https://facebook.com/pixel"
  },
  {
    id: '8',
    name: "Hotjar",
    image: require("@/components/TheSite/SiteApps/tempPics/hotjar.jpg"),
    serviceType: 'Analytics',
    status: APP_STATUS.COMING_SOON,
    description: "Hotjar is an open-source, full-stack product analytics platform. \n Track usage, performance, and user behavior in real-time.",
    docs: "https://docs.getforge.com/guides/hotjar",
    product_page: "https://hotjar.com"
  },
  {
    id: '9',
    name: "Mixpanel",
    image: require("@/components/TheSite/SiteApps/tempPics/mixpanel.jpg"),
    serviceType: 'Analytics',
    status: APP_STATUS.COMING_SOON,
    description: "Track usage, performance, and user behavior in real-time.",
    docs: "https://docs.getforge.com/guides/mixpanel",
    product_page: "https://mixpanel.com"
  },
  {
    id: '10',
    name: "Amplitude",
    image: require("@/components/TheSite/SiteApps/tempPics/amplitude.jpg"),
    serviceType: 'Analytics',
    status: APP_STATUS.COMING_SOON,
    description: "Track usage, performance, and user behavior in real-time.",
    docs: "https://docs.getforge.com/guides/amplitude",
    product_page: "https://amplitude.com"
  },
  {
    id: '11',
    name: "Kissmetrics",
    image: require("@/components/TheSite/SiteApps/tempPics/kissmetrics.jpg"),
    serviceType: 'Analytics',
    status: APP_STATUS.COMING_SOON,
    description: "A powerful, free and open-source analytics platform. \n Track usage, performance, and user behavior in real-time.",
    docs: "https://docs.getforge.com/guides/kissmetrics",
    product_page: "https://kissmetrics.com"
  },
  {
    id: '12',
    name: "Intercom",
    image: require("@/components/TheSite/SiteApps/tempPics/intercom.jpg"),
    serviceType: 'Analytics',
    status: APP_STATUS.COMING_SOON,
    description: "Provide a seamless customer support experience. \n Get insights on your audience and improve your app performance.",
    docs: "https://docs.getforge.com/guides/intercom",
    product_page: "https://intercom.com"
  },
  {
    id: '13',
    name: "Sentry",
    image: require("@/components/TheSite/SiteApps/tempPics/sentry.jpg"),
    serviceType: 'Error Tracking',
    status: APP_STATUS.COMING_SOON,
    description: "Sentry is an error tracking platform. \n Get insights on your audience and improve your app performance.",
    docs: "https://docs.getforge.com/guides/sentry",
    product_page: "https://sentry.io"
  },
  {
    id: '14',
    name: "Stripe",
    image: require("@/components/TheSite/SiteApps/tempPics/stripe.jpg"),
    serviceType: 'Payment Processing',
    status: APP_STATUS.COMING_SOON,
    description: "Stripe is a payment processing platform. \n Add a seamless payment experience to your site.",
    docs: "https://docs.getforge.com/guides/stripe",
    product_page: "https://stripe.com"
  },
  {
    id: '15',
    name: "PayPal",
    image: require("@/components/TheSite/SiteApps/tempPics/paypal.jpg"),
    serviceType: 'Payment Processing',
    status: APP_STATUS.COMING_SOON,
    description: "PayPal is a payment processing platform. \n Add a seamless payment experience to your site.",
    docs: "https://docs.getforge.com/guides/paypal",
    product_page: "https://paypal.com"
  },
  {
    id: '16',
    name: "Mailchimp",
    image: require("@/components/TheSite/SiteApps/tempPics/mailchimp.jpg"),
    serviceType: 'Email Marketing',
    status: APP_STATUS.COMING_SOON,
    description: "Mailchimp is an email marketing platform. \n Add a seamless email marketing experience to your site.",
    docs: "https://docs.getforge.com/guides/mailchimp",
    product_page: "https://mailchimp.com"
  },
  {
    id: '17',
    name: "SendGrid",
    image: require("@/components/TheSite/SiteApps/tempPics/sendgrid.jpg"),
    serviceType: 'Email Marketing',
    status: APP_STATUS.COMING_SOON,
    description: "SendGrid is an email marketing platform. \n Add a seamless email marketing experience to your site.",
    docs: "https://docs.getforge.com/guides/sendgrid",
    product_page: "https://sendgrid.com"
  },
  {
    id: '18',
    name: "Hubspot",
    image: require("@/components/TheSite/SiteApps/tempPics/hubspot.jpg"),
    serviceType: 'CRM',
    status: APP_STATUS.COMING_SOON,
    description: "Hubspot is a CRM platform. \n Add a seamless CRM experience to your site.",
    docs: "https://docs.getforge.com/guides/hubspot",
    product_page: "https://hubspot.com"
  },
  {
    id: '19',
    name: "Salesforce",
    image: require("@/components/TheSite/SiteApps/tempPics/salesforce.jpg"),
    serviceType: 'CRM',
    status: APP_STATUS.COMING_SOON,
    description: "Salesforce is a CRM platform. \n Add a seamless CRM experience to your site.",
    docs: "https://docs.getforge.com/guides/salesforce",
    product_page: "https://salesforce.com"
  },
  {
    id: '20',
    name: "Zendesk",
    image: require("@/components/TheSite/SiteApps/tempPics/zendesk.jpg"),
    serviceType: 'Customer Support',
    status: APP_STATUS.COMING_SOON,
    description: "Zendesk is a customer support platform. \n Add a seamless customer support experience to your site.",
    docs: "https://docs.getforge.com/guides/zendesk",
    product_page: "https://zendesk.com"
  },
  {
    id: '21',
    name: "Slack",
    image: require("@/components/TheSite/SiteApps/tempPics/slack.jpg"),
    serviceType: 'Communication',
    status: APP_STATUS.COMING_SOON,
    description: "Slack is a messaging app for team communication. \n Get insights on your audience and improve your app performance.",
    docs: "https://docs.getforge.com/guides/slack",
    product_page: "https://slack.com"
  },
  {
    id: '22',
    name: "Trello",
    image: require("@/components/TheSite/SiteApps/tempPics/trello.jpg"),
    serviceType: 'Project Management',
    status: APP_STATUS.COMING_SOON,
    description: "Trello is a project management platform. \n Get insights on your audience and improve your app performance.",
    docs: "https://docs.getforge.com/guides/trello",
    product_page: "https://trello.com"
  },
  {
    id: '23',
    name: "Webflow",
      image: require("@/components/TheSite/SiteApps/tempPics/webflow.jpg"),
    serviceType: 'Web Design',
    status: APP_STATUS.COMING_SOON,
    description: "Webflow is a web design platform. \n Create stunning websites with a drag and drop interface.",
    docs: "https://docs.getforge.com/guides/webflow",
    product_page: "https://webflow.com"
  },
  {
    id: '24',
    name: "Figma",
    image: require("@/components/TheSite/SiteApps/tempPics/figma.jpg"),
    serviceType: 'Design',
    status: APP_STATUS.COMING_SOON,
    description: "Figma is a design platform. \n Create stunning designs with a drag and drop interface.",
    docs: "https://docs.getforge.com/guides/figma",
    product_page: "https://figma.com"
  },
  {
    id: '25',
    name: "Canva",
    image: require("@/components/TheSite/SiteApps/tempPics/canva.jpg"),
    serviceType: 'Design',
    status: APP_STATUS.COMING_SOON,
    description: "Canva is a design platform. \n Create stunning designs with a drag and drop interface.",
    docs: "https://docs.getforge.com/guides/canva",
    product_page: "https://canva.com"
  },
  {
    id: '26',
    name: 'Optimizely',
    image: require("@/components/TheSite/SiteApps/tempPics/optimizely.jpg"),
    serviceType: 'A/B Testing',
    status: APP_STATUS.COMING_SOON,
    description: "Optimizely is an A/B testing platform. \n Get insights on your audience and improve your app performance.",
    docs: "https://docs.getforge.com/guides/optimizely",
    product_page: "https://optimizely.com"
  }
];

export class AppParseServer {
  id = '';
  url = '';
  appId = '';
  masterKey = '';
  clientKey = '';
  chiselUrl = '';
  dashboardPassword = '';
  site_app_id = '';
  redeploy = false;
  deployed = false;
  deploying = false;
  serviceType = 'PaaS';
  installCount = 1500;
  https_port = '';
  port = '';
  github_branch = "";
  github_repository = "";
  appData = APPS_DATA.find(d => d.id == APP_PARSE_SERVER);
  environments = {};
  serviceHealth = 'loading';
  parse_logs_available = false;
  cloud_code_enabled = false;
  messages = [];
  parse_dashboard_url = "";
  parse_c66_ssh_key = "";
  parse_server_app_url = "";

  constructor(origin, status = false, site_app) {
    if (!origin)
      return;
    this.https_port = origin.https_port;
    this.port = origin.port;
    this.github_branch = origin.github_branch == null ? "" : origin.github_branch;
    this.github_repository = origin.github_repository == null ? "" : origin.github_repository;
    this.url = origin.url == undefined ? '' : origin.url;
    this.appId = origin.app_id;
    this.masterKey = origin.master_key;
    this.clientKey = origin.client_key;
    this.chiselUrl = origin.chisel_cms_url == undefined ? '' : origin.chisel_cms_url;
    this.dashboardPassword = origin.dashboard_pass == undefined ? '' : origin.dashboard_pass;
    this.activated = status;
    this.id = origin.id;
    this.parse_c66_ssh_key = site_app.parse_c66_ssh_key;
    this.environments = site_app.environments;
    if (this.environments == null)
      this.environments = [];
    this.site_app_id = origin.site_app_id;
    this.deployed = origin.deployed;
    this.redeploy = origin.redeploy;
    this.deploying = origin.deploying;
    this.parse_server_app_url = site_app.parse_server_app_url;
    this.parse_logs_available = origin.parse_logs_available;
    this.cloud_code_enabled = site_app.cloud_code_enabled;
    this.parse_dashboard_url = site_app.parse_dashboard_url;
    if (!origin.parse_logs_available) 
      this.serviceHealth = 'not deployed'
  }

  getServerObject() {
    return {
      app_id: this.appId,
      chisel_cms_url: this.chiselUrl,
      master_key: this.masterKey,
      client_key: this.clientKey,
      github_repository: this.github_repository,
      github_branch: this.github_branch,
      parse_dashboard_pass: this.dashboardPassword
    }
  }
}

export class AppChiselCMS {
  id = '';
  url = '';
  parse_app_id = '';
  restkey = '';
  jskey = '';
  chiselUrl = '';
  site_app_id = '';
  deployed = false;
  activated = false;
  parse_url = "";
  serviceType = 'headless CMS';
  installCount = 1500;
  current_version_id = 0;
  messages = [];
  percent_deployed = 1;

  appData = APPS_DATA.find(d => d.id == APP_CHISEL_CMS);


  constructor(origin, status = false, percent_deployed = 1) {
    if (!origin)
      return;
    this.id = origin.id;
    this.parse_url = origin.parse_url == null ? "" : origin.parse_url;
    this.url = origin.url == null ? "" : origin.url;
    this.parse_app_id = origin.parse_app_id;
    this.restkey = origin.restkey;
    this.jskey = origin.jskey;
    this.current_version_id = origin.current_version_id;
    this.activated = status;
    this.site_app_id = origin.site_app_id;
    this.deployed = origin.deployed;
    this.percent_deployed = percent_deployed;
    if (localStorage.getItem(`version_${this.current_version_id}`) == null)
      this.messages = []
    else
      this.messages = JSON.parse(localStorage.getItem(`version_${this.current_version_id}`));      
  }

  getServerObject() {
    return {
      id: this.id,
      url: this.url,
      parse_app_id: this.parse_app_id,
      restkey: this.restkey,
      site_app_id: this.site_app_id,
      jskey: this.jskey,
      parse_url: this.parse_url
    }
  }
}
