import Vue from "vue";
import Vuex, { createLogger } from "vuex";

import application from "./modules/application";
import organisations from "./modules/organisations";
import projects from "./modules/projects";
import sites from "./modules/sites";
import versions from "./modules/versions";
import user from "./modules/user";
import invoices from "./modules/invoices";
import site_app from "./modules/site_app";
import site_user from "./modules/site_user";
import parse_cloud from "./modules/parse_cloud";
import project_user from "./modules/project_user";
import organisation_user from './modules/organisation_user';
import invitation from "./modules/invitation";
import form from './modules/form';
import formBuilder from './modules/formBuilder';
import comments from './modules/comments';
import notifications from './modules/notifications';
import publisher_infos from './modules/publisher_infos';
import publisher from './modules/publisher';
import plugin_publishing from './modules/plugin_publishing';
import profile_config from './modules/profile_config';
import organisation_subscriptions from './modules/organisation_subscriptions'
import app_interest from './modules/app_interest';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    application,
    organisations,
    projects,
    sites,
    versions,
    user,
    invoices,
    site_user,
    site_app,
    parse_cloud,
    project_user,
    organisation_user,
    invitation,
    form,
    formBuilder,
    comments,
    notifications,
    publisher_infos,
    publisher,
    plugin_publishing,
    profile_config,
    organisation_subscriptions,
    app_interest
  },
  // plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : [],
  // plugins: [createLogger()]
});

export default store;