<template>
  <v-card class="ModalCreateProject modal_window">
    <h4 class="ModalCreateProject__title">
      Create new project
    </h4>
    <v-card-subtitle>
      A Project is a great way to organise your sites and plugins
    </v-card-subtitle>
    <v-card-text class="mt-2">
      <span class="ModalCreateProject__label">Enter project name</span>
      <v-text-field
        v-model.trim="name"
        class="pa-0 ModalCreateProject__input"
        autofocus
        hide-details
        filled
        dense
      />
      <div v-if="errorMain" class="error-main mt-4 pa-4">
        {{ errorMain }}
      </div>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions class="pa-6 ModalCreateProject__footer">
      <v-btn @click="close" color="n5">Cancel</v-btn>
      <v-btn 
        @click="onCreate" 
        color="y1"
        :disabled="!name || name.length === 0"
      >
        Create project
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ModalCreateProject",
  data() {
    return {
      name: '',
      errorMain: ""
    }
  },
  methods: {
    onCreate() {
      console.log('onCreate triggered with name:', this.name);
      console.log('Current organisation:', this.$store.state.organisations.current);
      console.log('Current user:', this.$store.state.user.current);

      if (!this.name) {
        console.log('Name validation failed');
        return;
      }

      const projectData = {
        project: {
          name: this.name,
          ...(this.$store.state.organisations.current.id === 0 
            ? { user_id: this.$store.state.user.current.id }
            : { organisation_id: this.$store.state.organisations.current.id })
        }
      };

      console.log('Dispatching projects/create with data:', projectData);

      this.$store.dispatch('projects/create', projectData)
      .then(() => {
        console.log('Project creation succeeded');
        this.$emit('close');
      })
      .catch(e => {
        console.error('Project creation failed:', e);
        this.errorMain = "Project name should be unique";
      })
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped lang="scss">
  .error-main {
    background: #E94A53;
    border-radius: 2px;
  }

  $style: ModalCreateProject;
  .#{$style} {
    &.theme--dark.v-card {
      @extend %modal-body-styles;
      min-width: 600px;
      padding: 24px 24px 0;
    }
    &__title {
      font-weight: 500;
      font-size: $H16;
      color: $white;
      margin-bottom: 32px;
    }
    &__label {
      @extend %label-styles;
    }
    &__input {
      &.v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot {
        @extend %input-styles;
      }
      &.v-input input {
        color: $white;
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px 0;
      gap: 16px;
      & > .theme--dark.v-btn {
        color: $white;
      }
    }
  }
</style>