<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="500px"
    content-class="first-time-wizard"
  >
    <v-card class="first-time-wizard__card">
      <v-btn
        icon
        small
        class="first-time-wizard__close"
        @click="$emit('input', false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      

      <v-card-text class="first-time-wizard__content">
        <template v-if="currentStep === 1">
          <h2 class="first-time-wizard__title">Start your free Forge trial</h2>
          <p class="first-time-wizard__subtitle">Let's create your first Project</p>
          <p class="first-time-wizard__description">Projects help you organize your sites.</p>
          
          <v-text-field
            v-model="projectName"
            label="Project Name"
            :error-messages="projectError"
            @input="projectError = ''"
            dark
            class="first-time-wizard__input"
            placeholder="My First Project"
            @keyup.enter="createProject"
          ></v-text-field>
        </template>

        <template v-else>
          <h2 class="first-time-wizard__title">Great progress!</h2>
          <p class="first-time-wizard__subtitle">Now, let's create your first site</p>
          <p class="first-time-wizard__description">Choose a subdomain for your site</p>
          
          <v-text-field
            v-model="siteName"
            class="first-time-wizard__input"
            autofocus
            hide-details
            filled
            dense
            :error-messages="siteError"
            @input="siteError = ''"
            :placeholder="isFreePlan ? 'mysite.frge.io' : 'mysite.getforge.io'"
          />
          
          <p class="first-time-wizard__domain-preview" v-if="siteName">
            Your site will be available at: {{ siteName }}
          </p>
        </template>
      </v-card-text>

      <v-card-actions class="first-time-wizard__actions">
        <v-btn
          :color="currentStep === 1 ? 'primary' : 'y1'"
          @click="currentStep === 1 ? createProject() : createSite()"
          :loading="currentStep === 1 ? projectLoading : siteLoading"
          :disabled="(currentStep === 1 && !projectName) || (currentStep === 2 && !siteName)"
          class="first-time-wizard__button"
        >
          {{ currentStep === 1 ? 'Continue' : 'Create Site' }}
        </v-btn>
      </v-card-actions>
      <div class="first-time-wizard__progress">
        <div class="first-time-wizard__step" :class="{ '_active': currentStep === 1, '_complete': currentStep > 1 }">
          <div class="first-time-wizard__step-number">1</div>
          <div class="first-time-wizard__step-label">Create Project</div>
        </div>
        <div class="first-time-wizard__step" :class="{ '_active': currentStep === 2 }">
          <div class="first-time-wizard__step-number">2</div>
          <div class="first-time-wizard__step-label">Create Site</div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { generateDomain } from '@/utils/common';

export default {
  name: "FirstTimeWizard",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    initialStep: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      currentStep: 1,
      projectName: "",
      siteName: "",
      projectError: "",
      siteError: "",
      projectLoading: false,
      siteLoading: false
    };
  },
  created() {
    this.currentStep = this.initialStep;
    // Generate initial domain name
    let planId = !this.isOrganisation ? 
      this.$store.state.user.current.plan_id : 
      this.$store.state.organisations.current.organisation_subscription_id;
    this.siteName = generateDomain(planId);
    // If we're in a project context, start at step 2
    if (this.$store.state.projects.active) {
      console.log('Starting wizard at step 2 - project context:', this.$store.state.projects.active.id);
      this.currentStep = 2;
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.current
    }),
    show: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); }
    },
    isFreePlan() {
      const isFree = this.user.plan_id === 'free' || this.user.on_trial;
      return isFree;
    },
    isOrganisation() {
      const isOrg = this.$store.state.organisations.current.id !== 0;
      return isOrg;
    },
    domain() {
      const domain = this.isFreePlan ? 'frge.io' : 'getforge.io';
      console.log('Domain selection:', {
        planId: this.user.plan_id,
        isFreePlan: this.isFreePlan,
        selectedDomain: domain
      });
      return domain;
    },
    cleanSiteName() {
      if (!this.siteName) return '';
      const cleaned = this.siteName.replace(/\.[^/.]+$/, "").replace(/[^a-zA-Z0-9-]/g, "").toLowerCase();
      console.log('Site name cleaning:', {
        original: this.siteName,
        cleaned: cleaned
      });
      return cleaned;
    },
    formattedSiteName() {
      if (!this.siteName) return '';
      const formatted = `${this.cleanSiteName}.${this.domain}`;
      console.log('Final URL formation:', {
        cleanName: this.cleanSiteName,
        domain: this.domain,
        finalUrl: formatted
      });
      return formatted;
    }
  },
  methods: {
    async createProject() {
      if (!this.projectName) {
        this.projectError = "Project name is required";
        return;
      }

      this.projectLoading = true;
      try {
        const projectData = {
          project: {
            name: this.projectName,
            ...(this.$store.state.organisations.current.id === 0 
              ? { user_id: this.$store.state.user.current.id }
              : { organisation_id: this.$store.state.organisations.current.id })
          }
        };
        
        console.log("Creating project:", projectData);
        
        const response = await this.$store.dispatch("projects/create", projectData);
        await this.$nextTick();
        
        // Navigate to the project's sites page
        this.$router.push(`/project/${response.id}/sites`);
        
        // Check if project has no sites, then show step 2
        if (!response.sites_count || response.sites_count === 0) {
          this.currentStep = 2;
        } else {
          this.$emit('input', false); // Close wizard if project already has sites
        }
      } catch (error) {
        console.error("Project creation error:", error);
        this.projectError = error.message || "Failed to create project";
      } finally {
        this.projectLoading = false;
      }
    },
    async createSite() {
      if (!this.siteName) return;

      this.siteLoading = true;
      try {
        const project = this.$store.state.projects.active;
        if (!project) {
          throw new Error("No project found. Please try again.");
        }

        // Use the siteName directly as it already has the correct format
        const siteData = {
          url: this.siteName,
          project_id: project.id
        };

        if (this.isOrganisation) {
          siteData.organisation_id = this.$store.state.organisations.current.id;
        }

        console.log('Creating site with data:', siteData);

        const response = await this.$store.dispatch("sites/create", siteData);
        
        if (response && response.id) {
          console.log('Site created successfully:', response);
          await this.$store.dispatch('sites/setActive', response.id);
          this.$emit('completed');
          this.$router.push(`/site/${response.id}/versions?celebrate=firstsite`);
        } else {
          console.error('Store response:', response);
          throw new Error("Site creation failed - no response received");
        }
      } catch (error) {
        console.error("Site creation error:", error);
        if (error?.body?.errors) {
          this.siteError = Array.isArray(error.body.errors) 
            ? error.body.errors.join('. ')
            : Object.values(error.body.errors).join('. ');
        } else {
          this.siteError = error.message || "Failed to create site";
        }
      } finally {
        this.siteLoading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.first-time-wizard {
  &__card.v-card {
    background: rgba(37, 36, 55, 0.9) !important;
    border: 1px solid #2e2c42;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    border-radius: 8px !important;
    padding: 24px;
  }

  &__progress {
    display: flex;
    justify-content: center;
    gap: 32px;
    margin-top: 24px;
    margin-bottom: 4px;
  }

  &__step {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #a4a4ae;

    &._active {
      color: white;
      .first-time-wizard__step-number {
        background: #496ddb;
        border-color: #496ddb;
      }
    }

    &._complete {
      .first-time-wizard__step-number {
        background: #496ddb;
        border-color: #496ddb;
      }
    }
  }

  &__step-number {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #2e2c42;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
  }

  &__step-label {
    font-size: 14px;
    font-weight: 500;
  }

  &__content {
    text-align: left;
    padding: 0 16px;
  }

  &__title {
    color: white;
    font-size: 24px;
    font-weight: 700;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__subtitle {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-bottom: 0px;
  }

  &__description {
    color: #a4a4ae;
    font-size: 12px;
    margin-bottom: 24px;
    text-align: left;
  }

  &__input {
    width: 100%;
    margin: 24px auto !important;
    border-radius: 8px !important;

    .v-input__slot {
      background: rgba(37, 36, 55, 0.9) !important;
      border: 1px solid #2e2c42 !important;
      padding: 12px 16px !important;
      transition: all 0.2s ease;
      min-height: 56px !important;
      
      &:hover {
        border-color: #496ddb !important;
      }
      
      &:focus-within {
        border-color: #496ddb !important;
        box-shadow: 0 0 0 2px rgba(73, 109, 219, 0.2);
      }
    }

    .v-text-field__details {
      padding: 4px 16px 0;
      min-height: 24px;
    }

    input {
      color: white !important;
      font-size: 16px !important;
      padding-top: 8px !important;
      border-radius: 8px !important;
      &::placeholder {
        color: rgba(164, 164, 174, 0.7) !important;
        font-size: 16px;
      }
    }

    .v-label {
      color: #a4a4ae !important;
      font-size: 14px !important;
      top: 8px !important;
      transform-origin: left top;
      transition: all 0.2s ease;
    }

    &.v-input--is-focused .v-label,
    &.v-input--is-dirty .v-label {
      transform: translateY(-18px) scale(0.75) !important;
      color: #496ddb !important;
    }

    &.error--text {
      .v-input__slot {
        border-color: #ff5252 !important;
      }
      .v-label {
        color: #ff5252 !important;
      }
    }
  }

  &__actions {
    justify-content: center;
    padding: 16px 0 0;
  }

  &__button {
    min-width: 120px;
    text-transform: none !important;
    font-weight: 500 !important;
  }

  &__domain-preview {
    color: #a4a4ae;
    font-size: 14px;
    margin-top: 8px;
    font-style: italic;
  }

  &__close {
    position: absolute !important;
    top: 16px;
    right: 16px;
    color: #a4a4ae !important;
  }

  &__button.v-btn {
    min-width: 120px;
    text-transform: none !important;
    font-weight: 500 !important;

    .v-btn__loader {
      // Optional: customize loader color if needed
      .v-progress-circular {
        color: white;
      }
    }
  }
}
</style>